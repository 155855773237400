import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_Messi.module.scss';
import TablaGeneral from "./TablaGeneral"
import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import twitter from "../images/icons/twitter.svg"
import prize from "../images/messi644/RojafrenteOK.png"
import messiBkg from "../images/messi644/IlustracionMessi-01.png"
import BurgerMenu from './BurgerMenu';
import MessiFooter from './MessiFooter';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import HamburgerMenu from 'react-hamburger-menu';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

const Messi = ({ props }) => {
    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    const [activeG, setActiveG] = useState(true);
    const [activeT, setActiveT] = useState(false);
    const [user, setUser] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (!user) {
            if (state.user) {
                if (state.user.logged) setUser(state.user)
            } else {
                let cookie = Cookies.get('bud')
                if (cookie) {
                    setUser({ logged: true, user: { idUser: cookie } })
                    dispatch({ type: "SET_USER", user: { logged: true, user: { idUser: cookie } } })
                }
                else {
                    setUser(false)
                }
            }
        }
    }, [user])

    const handleClick = (e) => {
        if (e.target.name === "general") {
            setActiveG(true)
            setActiveT(false)
        } else {
            setActiveT(true)
            setActiveG(false)
        }
    }

    const handleToggle = () => {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }

    return (
        <div style={{ overflowY: 'hidden !important' }}>
            <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
            <div className={styles.menu}>
                <HamburgerMenu
                    isOpen={toggle}
                    menuClicked={handleToggle}
                    width={23}
                    height={16}
                    strokeWidth={1}
                    rotate={0}
                    color='white'
                    borderRadius={0}
                    animationDuration={0.5}
                />
            </div>
            <BurgerMenu props={props} handleClick={handleClick} toggle={toggle} handleToggle={handleToggle} setUser={setUser} />
            <img src={messiBkg} className={styles.imgBackground} />
            <div className={styles.messiHome}>
                <div className={styles.heroBlock}  >
                    <div className={styles.middle}>
                        <h1>Messi</h1>
                        <h2>644</h2>
                        <p>Leo está muy cerca de romper el récord de 644 goles en un mismo club. Y vos estás a solo 5 preguntas de poder ganar una heladera llena de Buds, merch inspirado en el King, y muchos premios más.</p>
                    </div>
                    <div style={{ position: 'relative', bottom: 0 }}>
                        {/* <div onClick={() => {
                            if (user.logged) props.navigate('micuenta')
                            else props.navigate('quiz')
                        }} className={styles.btnFirst} >Empezar</div> */}
                        {state.user.logged ? (
                            <div
                                onClick={() => props.navigate("micuenta")}
                                className={styles.btnSecond}>Mi cuenta</div>
                        ) : (
                                <div
                                    onClick={() => props.navigate("login")}
                                    className={styles.btnSecond}>Iniciar sesión / Registrarme</div>
                            )}
                    </div>
                </div>
                <div className={styles.rrssFooter}>
                    <div>
                        <a target="_blank" href="https://www.instagram.com/budargentina/"><img src={instagram} alt="Instagram" /></a>
                        <a target="_blank" href="https://www.facebook.com/BudweiserArgentina/"><img src={facebook} alt="Facebook" /></a>
                        <a target="_blank" href="https://twitter.com/budargentina"><img src={twitter} alt="Twitter" /></a>
                    </div>
                    <p>Políticas de Privacidad</p>
                </div>
                <Element id="rankGeneral" name="rankGeneral" className={styles.tablesContainer}>
                    <h2>Tabla de posiciones</h2>
                    <div className={styles.toggleTable}>
                        <button type="button" name="general" className={activeG ? styles.isActive : styles.notActive} onClick={(e) => { handleClick(e) }}>Generales</button>
                        <button type="button" name="torneo" className={activeT ? styles.isActive : styles.notActive} onClick={(e) => {
                            if (user) handleClick(e)
                            else props.navigate("login")
                        }}>Mis torneos</button>
                    </div>
                    <TablaGeneral activeG={activeG} activeT={activeT} props={props} usuario={user} />
                </Element>
                <div className={styles.prizeContainer}>
                    <div className={styles.prizeTxt}>
                        <h3>Podés ganar</h3><h3>una heladera llena de Buds</h3>
                    </div>
                    <img src={prize} />
                </div>
                {/* <div className={styles.create}>
                    <h2>Creá tu torneo</h2>
                    <h3>competí contra tus amigos y compará los resultados.</h3>
                    <div onClick={() => {
                        if (user.logged) props.navigate('tournament')
                        else props.navigate('login')
                    }} className={styles.btnSecond}>Empezar</div>
                </div> */}
                <MessiFooter />
            </div>
        </div>
    )
}




export default Messi