import React, { useEffect, useRef, useState } from 'react';
import styles from '../scss/components/_Thankyou.module.scss';
import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import twitter from "../images/icons/twitter.svg"
import youtube from "../images/icons/youtube2.svg"
import messiBkg from "../images/messi644/IlustracionMessi-01.png"
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import downloadIcon from "../images/icons/down-arrow.svg"
import close from "../images/icons/close.svg"
import Prode from './Prode';
var htmlToImage = require('html-to-image');
 


const Thankyou = ({ props }) => {

    const [visible, setVisible] = useState(true)
    const [elem, setElem] = useState(false)
    const [loading, setLoading] = useState(false)
    const referencia = useRef();

    useEffect(() => {
        setElem(referencia.current);
    }, [referencia]);

    const handleClose = () => {
        props.navigate("/messi644/micuenta")
    }

    const downloadProde = () => {
        let prod = document.getElementById('prode')
        console.log(prod.offsetWidth)
        setLoading(true)
        prod.style.marginLeft = 0
        htmlToImage.toJpeg(document.getElementById('prode'), {
            quality: 1,
            cacheBust: true, pixelRatio: 1, 
            // style: {
            //        display: 'block'
            //      }
        })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'messi644.jpeg';
                link.href = dataUrl;
                link.click();
                setLoading(false)
                prod.style.marginLeft = '5000px'
            });
    }

    return (
        <>
            <div className={styles.superCont}>
                {/* <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style> */}
                <div className={styles.thanksContainer}>
                    <img src={messiBkg} className={styles.imgBackground} />
                    <div className={styles.zIndex}>
                        <img onClick={handleClose} src={close} alt="close" className={styles.close} />
                        <img src={budweiser} className={styles.logo} alt="Budweiser" />
                        <h3>Gracias por<br />participar.</h3>
                        <p>Después de cada gol del King,<br />REALIZAREMOS EL SORTEO ENTRE LOS<br/>QUE HAYAN ACERTADO.</p>
                        <p>NOS CONTACTAREMOS AL MAIL<br />SI SOS UNO DE LOS GANADORES.</p>
                        {loading ? (<img style={{ width: '15%', height: 'auto', marginTop: '30px' }} src={require("../images/messi644/spinner.gif")} />) : (
                           <div>
                           <div onClick={downloadProde} className={styles.btnSecond}>
                                <img src={downloadIcon} />
                                Descargar mi pronóstico
                            </div>
                            </div>
                        )}

                        <div className={styles.rrssContainer}>
                            <a href="https://www.instagram.com/budargentina/"><img src={instagram} alt="Instagram" /></a>
                            <a href="https://www.facebook.com/BudweiserArgentina/"><img src={facebook} alt="Facebook" /></a>
                            <a href="https://twitter.com/budargentina"><img src={twitter} alt="Twitter" /></a>
                            <a href="https://www.youtube.com/user/budargentina"><img alt="youtube" src={youtube} /></a>
                        </div>
                    </div>
                </div>
                {visible ? <Prode props={props} referencia={referencia} /> : null}
            </div>
        </>
    )
}

export default Thankyou