import React from 'react';
import styles from '../scss/components/_InvitarCodigo.module.scss';
import close from "../images/icons/close.svg";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const InvitarCodigo = ({ props, showCode, setToshare }) => {
    const handleClose = () => {
        setToshare ? setToshare(false) : props.navigate("/messi644/home")
    }
    return (
        <div>
            <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>            <div className={styles.thanksContainer}>
                <div className={styles.popUp}>
                    <img src={close} onClick={() => { handleClose() }} className={styles.close} />
                    <h1>Comparte el código de tu torneo y suma más amigos</h1>
                    <input type="text" placeholder={showCode} value={showCode} />
                    <div className={styles.linePlace}></div>
                    <CopyToClipboard text={`Sumate a mi torneo de Budweiser: ${showCode}  https://www.budweiser.com.ar/messi644/tournament/${showCode}`}>
                        <div onClick={() => props.navigate('/messi644/home')} className={styles.btnFirst}>Copiar código</div>
                    </CopyToClipboard>

                </div>
            </div>
        </div>
    )
}

export default InvitarCodigo