import React, { useContext, useState } from 'react';
import styles from '../scss/components/_Register.module.scss';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import close from "../images/icons/close.svg"
import budweiser from "../images/messi644/logoBudweiser-01.svg"

const Change = (props) => {
    const dispatch = useContext(GlobalDispatchContext)
    const [password, setPassword] = useState(false);
    const [passwordConf, setPasswordConf] = useState(false);
    const [message, setMessage] = useState(false)
    const [error, setError] = useState(false)
    const [errorValidation, setErrorValidation] = useState(false)
    const location = props.location.state

    let id = props.id

    const update = (e) => {
        e.preventDefault()
        if (password.length < 6) {
            return setErrorValidation({
                target: "password",
                msg: "*La contraseña debe contener al menos 6 caracteres!"
            });
        }
        if (password != passwordConf) {
            return setErrorValidation({
                target: "passwordConf",
                msg: "*Las contraseñas no coinciden!"
            });
        }
        else {
            setErrorValidation({})
            Cookies.remove('bud')
            fetch(`https://wabudweiser-prd.azurewebsites.net/forgotpassword/changepassword`, {
                method: 'POST',
                body: JSON.stringify({ password, id }),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(async response => {
                    if (response.ok) {
                        setMessage("Tu nueva contraseña fue registrada exitosamente!")
                        setTimeout(() => {
                            props.navigate('/messi644/login')
                        }, 2000);
                    } else {
                        let msj = await response.json()
                        console.log(msj)
                        setError(msj)
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        }
    }

    return (
        <div>
            <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
            {/* <img src={bottle} className={styles.imgBackground} /> */}
            <div className={styles.navCont} >
                <img onClick={() => { props.navigate('/messi644') }} src={budweiser} className={styles.imgLogo} />
                <img onClick={() => window.history.back()} src={close} className={styles.closeIcon} />
            </div>
            <form onSubmit={update}>
                <div className={styles.formContainer}>
                    <h3>Ingresá tu nueva contraseña</h3>
                    {errorValidation.target == 'password' ? (<p>{errorValidation.msg}</p>) : null}
                    <input type='password' placeholder="contraseña nueva" onChange={e => setPassword(e.target.value)} required></input>
                    {errorValidation.target == 'passwordConf' ? (<p>{errorValidation.msg}</p>) : null}
                    <input type='password' placeholder="confirmar contraseña" onChange={e => setPasswordConf(e.target.value)} required></input>
                    {errorValidation.target == 'provincia' ? (<p>{errorValidation.msg}</p>) : null}
                    {message ? <p className={styles.mensaje}>{message}</p> : null}
                    <div className={styles.btnCont}>
                        <div className={styles.error}>
                            {error ? (<p>{error}</p>) : null}
                        </div>
                        <button className={styles.btnFirst} type='submit'>Confirmar</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Change;