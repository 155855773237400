import React, { Fragment, useEffect } from "react"
import { Router, redirectTo } from "@reach/router"
import Messi from "../components/Messi"
import Quiz from "../components/Quiz"
import MiCuenta from "../components/MiCuenta"
import Login from "../components/Login"
import Register from "../components/Register"
import Thankyou from "../components/Thankyou"
import Tournament from "../components/Tournament"
import Prode from "../components/Prode"
import '../scss/index.scss'
import MiCuentaCodigo from "../components/MiCuentaCodigo"
import Forgot from "../components/ForgotPassword"
import Change from "../components/ChangePassword"
import SEO from "../components/seo"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie";


const Messi644 = (props) => {

  useEffect(()=>{
    console.log("UseEffect")
    let local = sessionStorage.getItem('age')
    console.log("LOCAL",local)
    let cookie = Cookies.get('age')
    let path = props.location.pathname
    console.log("PATH",path)
    console.log("Cookie",cookie)
    if (path == '/messi644/' || path == '/messi644') redirectTo("/messi644/home")
    if (!cookie && !local) {
      if(path == '/messi644/quiz'|| path == '/messi644/home')redirectTo("/messi644/agegate")
    }
  },[])

  return (
    <Fragment>
      <SEO title="Messi 644"/>
        <Router basepath="/messi644">
          <Messi props={props} path="/home" />
          <Login path="/login" />
          <Register path="/register" />
          <Quiz props={props} exact path="/quiz" />
          <MiCuenta props={props} path="/micuenta" />
          <Tournament props={props} path="/tournament" />
          <MiCuentaCodigo props={props} path="/tournament/:id" />
          <Thankyou props={props} path="/thankyou" />
          <Prode props={props} path="/prode" />
          <Forgot props={props} path="/forgot" />
          <Change props={props} path="/forgot/:id" />
          <AgeGate props={props} path="/agegate" ruta="/messi644/home"/>
        </Router>
    </Fragment>
  )
}
export default Messi644;