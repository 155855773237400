import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_Prode.module.scss';
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import messiKing from "../images/messi644/Trazado124.svg"
import kingFootball from "../images/messi644/Grupo63.svg"
import messiBkg from "../images/messi644/IlustracionMessi-01.png"
import finalizado from "../images/messi644/finalizado.svg"
import { GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";

const Prode = ({ props }) => {

    const state = useContext(GlobalStateContext)
    const [myGoals, setMyGoals] = useState(false);
    const [user, setUser] = useState(false)
    const [name, setName] = useState(false)

    useEffect(() => {
        if (!user) {
            if (state.user) {
                if (state.user.logged) {
                    let idUser = state.user.user.idUser
                    setUser(state.user)
                    fetchMyGoals(idUser)
                }
                else {
                    let cookie = Cookies.get('bud')
                    if (cookie) {
                        fetchMyGoals(cookie)
                        setUser({ logged: true, user: { idUser: cookie } })
                    }
                    else {
                        props.navigate('/messi644/login')
                    }
                }
            } else {
                let cookie = Cookies.get('bud')
                if (cookie) {
                    fetchMyGoals(cookie)
                    setUser({ logged: true, user: { idUser: cookie } })
                }
                else {
                    props.navigate('/messi644/login')
                }
            }
        }
    }, [])

    const fetchMyGoals = (idUser) => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/surveys/mygoalsinfo/${idUser}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    let g640 = []
                    let g641 = []
                    let g642 = []
                    let g643 = []
                    let g644 = []
                    data.map(gol => {
                        if (gol.gol === 640) g640.push(gol)
                        if (gol.gol === 641) g641.push(gol)
                        if (gol.gol === 642) g642.push(gol)
                        if (gol.gol === 643) g643.push(gol)
                        if (gol.gol === 644) g644.push(gol)
                        if (gol.fullName) setName(gol.fullName)
                    })
                    let obj = { 640: g640, 641: g641, 642: g642, 643: g643, 644: g644 }
                    setMyGoals({ 640: g640, 641: g641, 642: g642, 643: g643, 644: g644 })
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }


    return (
        <div id='prode' className={styles.superContainer}>
            {myGoals ? (
                <div  className={styles.prodeContainer} ref={props.referencia}>
                    <style>{'body { background: #CF152D}'}</style>
                    <img src={messiBkg} className={styles.imgBackground} />
                    <div className={styles.titleContainer}>
                        <div className={styles.titleCont}>
                            <h3>Messi</h3>
                            <img src={require('../images/messi644/gol644.svg')}/>
                        </div>
                        <div className={styles.userInfo}>
                            <img src={budweiser} />
                            <h3>Mi prode</h3>
                            <h4>{name ? name : null}</h4>
                        </div>
                    </div>
                    <div className={styles.golesContainer}>
                        <div className={styles.golCont}>
                            <h3>MESSI<img src={require('../images/messi644/640.svg')}/></h3>
                            <h4>Messi hará su gol #640:</h4>
                            {!myGoals[640].length ? (
                                <div className={styles.finalizado}>
                                    <div className={styles.textCont}>
                                        <h3>Gol finalizado</h3>
                                        <h4>No participé</h4>
                                    </div>
                                    <img src={finalizado} />
                                </div>
                            ) : (
                                    <>
                                        <p>{`${myGoals[640][0].answer},  ${myGoals[640][1].idAnswer == 7 ? 'DEL MINUTO 61 AL FINAL' : myGoals[640][1].answer} Y ${myGoals[640][2].answer}.`}</p>
                                        <h4>LA PELOTA:</h4>
                                        <p>{myGoals[640][3].answer}.</p>
                                        <h4>SERÁ:</h4>
                                        <p>{myGoals[640][4].answer}.</p>
                                    </>
                                )}
                        </div>
                        <div className={styles.golCont}>
                        <h3>MESSI<img src={require('../images/messi644/641.svg')}/></h3>
                            <h4>Messi hará su gol #641:</h4>
                            {!myGoals[641].length ? (
                                <div className={styles.finalizado}>
                                    <div className={styles.textCont}>
                                        <h3>Gol finalizado</h3>
                                        <h4>No participé</h4>
                                    </div>
                                    <img src={finalizado} />
                                </div>
                            ) : (
                                    <>
                                        <p>{`${myGoals[641][0].answer},  ${myGoals[641][1].idAnswer == 7 ? 'DEL MINUTO 61 AL FINAL' : myGoals[641][1].answer} Y ${myGoals[641][2].answer}.`}</p>
                                        <h4>LA PELOTA:</h4>
                                        <p>{myGoals[641][3].answer}.</p>
                                        <h4>SERÁ:</h4>
                                        <p>{myGoals[641][4].answer}.</p>
                                    </>
                                )}
                        </div>
                    </div>
                    <div className={styles.golesContainer}>
                        <div className={styles.golCont}>
                        <h3>MESSI<img src={require('../images/messi644/642.svg')}/></h3>
                            <h4>Messi hará su gol #642:</h4>
                            {!myGoals[642].length ? (
                                <div className={styles.finalizado}>
                                    <div className={styles.textCont}>
                                        <h3>Gol finalizado</h3>
                                        <h4>No participé</h4>
                                    </div>
                                    <img src={finalizado} />
                                </div>
                            ) : (
                                    <>
                                        <p>{`${myGoals[642][0].answer},  ${myGoals[642][1].idAnswer == 7 ? 'DEL MINUTO 61 AL FINAL' : myGoals[642][1].answer} Y ${myGoals[642][2].answer}.`}</p>
                                        <h4>LA PELOTA:</h4>
                                        <p>{myGoals[642][3].answer}.</p>
                                        <h4>SERÁ:</h4>
                                        <p>{myGoals[642][4].answer}.</p>
                                    </>
                                )}

                        </div>
                        <div className={styles.golCont}>
                            <h3>MESSI<img src={require('../images/messi644/643.svg')}/></h3>
                            <h4>Messi hará su gol #643:</h4>
                            {!myGoals[643].length ? (
                                <div className={styles.finalizado}>
                                    <div className={styles.textCont}>
                                        <h3>Gol finalizado</h3>
                                        <h4>No participé</h4>
                                    </div>
                                    <img src={finalizado} />
                                </div>
                            ) : (
                                    <>
                                        <p>{`${myGoals[643][0].answer},  ${myGoals[643][1].idAnswer == 7 ? 'DEL MINUTO 61 AL FINAL' : myGoals[643][1].answer} Y ${myGoals[643][2].answer}.`}</p>
                                        <h4>LA PELOTA:</h4>
                                        <p>{myGoals[643][3].answer}.</p>
                                        <h4>SERÁ:</h4>
                                        <p>{myGoals[643][4].answer}.</p>
                                    </>
                                )}
                        </div>
                    </div>
                    <div className={styles.quintoGol}>
                        <div>
                            <h3>MESSI<img className={styles.gol} src={require('../images/messi644/644.svg')}/></h3>
                            <div className={styles.quintoImgs}>
                                <img src={messiKing} />
                                <img src={kingFootball} />
                            </div>
                        </div>
                        {!myGoals[644].length ? (
                            <div className={styles.finalizado}>
                                <div className={styles.textCont}>
                                    <h3>Gol finalizado</h3>
                                    <h4>No participé</h4>
                                </div>
                                <img src={finalizado} />
                            </div>
                        ) : (
                                <>
                                    <h4>Messi hará su gol #644:</h4>
                                    <p>{`${myGoals[644][0].answer},  ${myGoals[644][1].idAnswer == 7 ? 'DEL MINUTO 61 AL FINAL' : myGoals[644][1].answer} Y ${myGoals[644][2].answer}.`}</p>
                                    <h4>LA PELOTA:</h4>
                                    <p>{myGoals[644][3].answer}.</p>
                                    <h4>SERÁ:</h4>
                                    <p>{myGoals[644][4].answer}.</p>
                                </>
                            )}
                    </div>
                </div>
            ) : (null)}
        </div>

    )
}

export default Prode;