import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_MiCuenta.module.scss';
import TablaGeneral from "./TablaGeneral"
import BurgerMenu from './BurgerMenu';
import MessiFooter from './MessiFooter';
import ModuloGol from "./ModuloGol";
import download from "../images/icons/down-arrow.svg"
import next from "../images/icons/arrow-right.svg";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import HamburgerMenu from 'react-hamburger-menu';
import { Element } from 'react-scroll';

const MiCuenta = ({ props }) => {

    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    const [activeG, setActiveG] = useState(true);
    const [activeT, setActiveT] = useState(false);
    const [user, setUser] = useState(false);
    const [myGoals, setMyGoals] = useState([{ idGoal: 640, completed: false }, { idGoal: 641, completed: false }, { idGoal: 642, completed: false }, { idGoal: 643, completed: false }, { idGoal: 644, completed: false }])
    const [goals, setGoals] = useState(false)
    const [goalCount, setGoalCount] = useState(0);
    const [points, setPoints] = useState(0);
    const [myTournaments, setMyTournaments] = useState(false);
    const [promedio, setPromedio] = useState(0);
    const [toggle, setToggle] = useState(false);

    const location = props.location.state

    useEffect(() => {
        if (!user) {
            if (state.user) {
                if (state.user.logged) {
                    let idUser = state.user.user.idUser
                    setUser(state.user)
                    fetchGoals()
                    fetchMyGoals(idUser)
                    fetchPoints(idUser)
                }
                else {
                    let cookie = Cookies.get('bud')
                    if (cookie) {
                        fetchGoals()
                        fetchMyGoals(cookie)
                        fetchPoints(cookie)
                        setUser({ logged: true, user: { idUser: cookie } })
                        dispatch({ type: "SET_USER", user: { logged: true, user: { idUser: cookie } } })
                    }
                    else {
                        props.navigate('/messi644/login')
                    }
                }
            } else {
                let cookie = Cookies.get('bud')
                if (cookie) {
                    fetchGoals()
                    fetchMyGoals(cookie)
                    fetchPoints(cookie)
                    setUser({ logged: true, user: { idUser: cookie } })
                    dispatch({ type: "SET_USER", user: { logged: true, user: { idUser: cookie } } })
                }
                else {
                    props.navigate('/messi644/login')
                }
            }
        }
        if (location && location.mitorneo) {
            setTimeout(() => {
                setActiveT(true)
                setActiveG(false)
            }, 1500);
        }
    }, [])

    const handleClick = (e) => {
        if (e.target.name === "general") {
            setActiveG(true)
            setActiveT(false)
        } else {
            if (state.user.logged) {
                setActiveT(true)
                setActiveG(false)
            } else {
                props.navigate('login')
            }
        }
    }

    const fetchGoals = () => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/goals`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    // console.log("Data FetchGoals", data)
                    setGoals(data)
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }
    const fetchMyGoals = (idUser) => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/surveys/mygoals/${idUser}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let goals = myGoals
                    let data = await response.json()
                    let count = 0
                    if (data.length) {
                        for (let i = 0; i < goals.length; i++) {
                            for (let j = 0; j < data.length; j++) {
                                if (goals[i].idGoal == data[j].idGoal) goals[i].completed = true
                            }
                        }
                    }
                    goals.map(gol => {
                        if (gol.completed) count++
                    })
                    setGoalCount(count)
                    setMyGoals(goals)
                    fetchCorrects(idUser, count)
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    const fetchPoints = (idUser) => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/surveys/${idUser}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    if (data.length) setPoints(data[0].Puntaje)
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    const fetchCorrects = (idUser, count) => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/surveys/correct/${idUser}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    if (data.length) {
                        //Hay respuestas correctas
                        let total = count ? count * 5 : 0
                        let correctas = data[0].Correctas
                        if (!total) setPromedio(0)
                        else {
                            let prom = Math.round((correctas * 100) / total)
                            setPromedio(prom)
                        }
                    }
                    else {
                        //No hay respuestas correctas
                        setPromedio(0)
                    }
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }
    const handleToggle = () => {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }

    return (
        <div>
            <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
            <div style={{ zIndex: 100, position: 'absolute', right: 0, marginTop: '70px', marginRight: '50px', cursor: "pointer" }}>
                <HamburgerMenu
                    isOpen={toggle}
                    menuClicked={handleToggle}
                    width={23}
                    height={16}
                    strokeWidth={1}
                    rotate={0}
                    color='white'
                    borderRadius={0}
                    animationDuration={0.5}
                />
            </div> 
            <BurgerMenu props={props} handleClick={handleClick} toggle={toggle} handleToggle={handleToggle} setUser={setUser}/>
            <div className={styles.nrosContainer}>
                <div className={styles.nrosDiv}>
                    <div>
                        <h3>{goalCount}</h3>
                        <h4>/5</h4>
                    </div>
                    <p>Goles<br />completados</p>
                </div>
                <div className={styles.divisorNro}></div>
                <div className={styles.nrosDiv}>
                    <div>
                        <h3>{promedio}</h3>
                        <h4>%</h4>
                    </div>
                    <p>Precisión</p>
                </div>
                <div className={styles.divisorNro}></div>
                <div className={styles.nrosDiv}>
                    <h3>{points}</h3>
                    <p>Puntos<br />acumulados</p>
                </div>
            </div>
            <ModuloGol misGoles={myGoals} goles={goals} props={props} goalCount={goalCount} />
            <div className={styles.navegaGolesContainer}>
            </div>
            {/* {goalCount !== 5 ? (
                <div className={styles.navegaGoles}>
                    <p>Navegá los goles</p>
                    <img style={{ width: "7px" }} src={next} alt="next" />
                </div>
            ) : (
                    <div onClick={() => { console.log("PRODE") }} className={styles.btnSecond}><img src={download} />Descargar mi prode</div>
                )} */}
             {!myGoals[4].completed ? (
                <div className={styles.navegaGoles}>
                    <p>Navegá los goles</p>
                    <img style={{ width: "7px" }} src={next} alt="next" />
                </div>
            ) : (
                    <div onClick={() => { props.navigate("thankyou") }} className={styles.btnSecond}>
                        <img src={download} />
                        mi pronóstico</div>
                )}
            <Element id="rankGeneral" name="rankGeneral" className={styles.tablesContainer}>
                <h2>Tabla de posiciones</h2>
                <div className={styles.toggleTable}>
                    <button type="button" name="general" className={activeG ? styles.isActive : styles.notActive} onClick={(e) => { handleClick(e) }}>Generales</button>
                    <button type="button" name="torneo" className={activeT ? styles.isActive : styles.notActive} onClick={(e) => { handleClick(e) }}>Mis torneos</button>
                </div>
                <TablaGeneral activeG={activeG} activeT={activeT} props={props} goalCount={goalCount} />
            </Element>

            <MessiFooter />
        </div>
    )
}

export default MiCuenta;